import { graphql } from 'gatsby';
import React from 'react';
import Post from '../../components/Post/Post';

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpConsulting(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfSingleConsulting {
				header {
					bgtype
					description
					image {
						url
						title
					}
					image_mobile {
							title
							url
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					vimeoId: popin_video
					title
					video {
						url
						type
						title
					}
				}
				caption_file
				file {
					filesize
					mime_type
					subtype
					title
					type
					url
				}
				blocks {
					acf_fc_layout
					columns {
						items {
							description
							number
							title
							type
						}
					}
					features {
						items {
							description
							number
						}
						color
						title
					}
					kpi {
						items {
							description
							number
						}
						color
						title
					}
					pictogrammes {
						items {
							description
							image {
								url
								title
							}
							title
						}
						color
						title
					}
					quote {
						author
						post
						quote
					}
					text {
						headline
						text
						title
						text2
					}
					text_bubble {
						color
						headline
						text
						title
					}
					text_visual {
						description
						image {
							title
							url
						}
						title
						type
					}
					video {
						image {
							url
							title
						}
						link
					}
					visuals {
						image {
							url
							title
						}
						image2 {
							url
							title
						}
						title
					}
					text_center {
							text
					}
				}
			}
		}
	}
`;

export default function ConsultingPost({ data }) {
	return (
		<Post data={data} withForm={true} />
	);
}
